import React, {useCallback, useEffect, useMemo} from "react"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import {useNavigate} from "react-router-dom";
import { Button } from "@jane/lib/src/components/form/Button"
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import {useTaskData} from "../../resources/TaskContext";
import {useClientData} from "../../resources/ClientContext";
import {usePermissions} from "../../resources/PermissionsHook";
import {useEmployeesData} from "../../resources/EmployeeContext";
import {useTeamData} from "../../resources/TeamContext";
import {Task, Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {getRelatedTeamIds, useTeamMap} from "../../components/team-select";
import DynamicDataTable from "../../components/data-table/dynamic-data-table";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarTimes, faCheckCircle,
  faClock, faExternalLink, faFileContract,
  faPhone,
  faSign,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {faCamera} from "@fortawesome/pro-light-svg-icons";

const RELOAD_INTERVAL = 20000

export default function TasksListPage(): JSX.Element {
  const { tasks, reload } = useTaskData()
  const { teams, isLoading: loadingTeams, } = useTeamData()

  const { teams: teamsApi } = useApiCall(config)
  const { canListTeams } = usePermissions()
  const { resource: teamResource, isLoading: loadingTeam } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams, isLoading: loadingSecondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)

  useEffect(() => {
    const timer = setInterval(reload, RELOAD_INTERVAL)
    return () => clearInterval(timer)
  }, [reload])

  return <div>
    <ContentContainer>
      <PageHeader>Taken overzicht</PageHeader>
      <p>
        Op basis van de teams of clienten waarmee u werkt, ziet u hier
        openstaande taken. U kunt filteren de deadline van de taak.
        Door op &lsquo;details&rsquo; te klikken opent u de taak en ziet u alle
        benodigde informatie om de taak uit te voeren. Indien u een taak
        per ongeluk heeft afgerond, kunt u deze onderaan deze pagina
        terugvinden en heropenen.
      </p>
    </ContentContainer>

    <Loading loading={!tasks || loadingTeams || loadingTeam || loadingSecondaryTeams}>
      { !! tasks && !! teams && <TaskBrowser tasks={tasks} teams={teams!} primaryTeam={teamResource!} secondaryTeams={secondaryTeams || []} reload={reload} /> }
    </Loading>
  </div>
}

function TaskBrowser(props: { tasks: Task[], teams: Team[], primaryTeam: Team|null, secondaryTeams: Team[], reload: () => void }): JSX.Element {
  const allTasks = useTaskView(props.tasks)
  const viewTasks = useMemo(() => {
    return allTasks
      .sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())
  }, [allTasks])

  return <div className={"mx-4"}>
    <div className="my-5">
      { viewTasks.length > 0 ? <TaskList tasks={viewTasks} teams={props.teams} primaryTeam={props.primaryTeam} secondaryTeams={props.secondaryTeams} /> : <div className={"flex flex-col items-center py-5"}>
        <FontAwesomeIcon icon={faCheckCircle} className={"text-brand-secondary text-3xl"} />
        <h2 className={"mt-4"}>Geen taken</h2>
        <p className={"text-muted"}>Er zijn binnen de huidige selectie geen taken gevonden.</p>
      </div> }
    </div>
  </div>
}


function TaskList(props: { tasks: ViewTask[], teams: Team[], primaryTeam: Team|null, secondaryTeams: Team[] }): JSX.Element {
  const teamMap = useTeamMap(props.teams, props.primaryTeam, props.secondaryTeams)
  const {canListAllTeams} = usePermissions()
  const navigate = useNavigate()
  const teamOptionsMap: Map<number, [string, number]> = useMemo(() => {
    return new Map([
      [-1, ['Alle', 0]],
      ...teamMap.filter(teams => teams[0] !== undefined)
        .map<[Team, number]>(teams => [teams.slice(-1)[0], teams.length])
        .map<[number, [string, number]]>(([team, indentation]) => [team.id, [team.name, indentation]])
    ])
  }, [teamMap])
  const teamFilterOptions: {[value: string]: string} = useMemo(() => {
    return Object.fromEntries(Array.from(teamOptionsMap).map(([id, [name, indentation]]: [number, [string, number]]): [string, string] => {
      return [id.toString(), (canListAllTeams ? "\xA0\xA0".repeat(indentation) : "") + name]
    }))
  }, [teamOptionsMap, canListAllTeams])
  /** Used to look up related teams */
  const relatedTeamsMap: {[teamId: string]: string[]} = useMemo(() => {
    return Object.fromEntries(props.teams.map((team) => {
      return [team.id.toString(), getRelatedTeamIds(team, teamMap).map(id => id.toString())]
    }))
  }, [teamMap, props.teams])
  return <>

    <DynamicDataTable<ViewTask>
      compact={true}
      searchableProperties={['typeText', 'statusText', 'subject', 'performer', 'user']}
      availableFilters={[
        {
          title: 'Team',
          property: 'performerId',
          options: teamFilterOptions,
          defaultValue: '-1',
          filterCallback: (row, filter) => {
            return filter === '-1'
              || (
                row.performerType === "team"
                && row.performerId !== null
                && relatedTeamsMap[filter]?.includes(row.performerId.toString())
              )
          },
        },
        {
          title: 'Deadline',
          property: 'deadline',
          options: {
            '1d': 'Binnen 1 werkdag',
            '3d': 'Binnen 3 dagen',
            '7d': 'Binnen 7 dagen',
            'all': 'Onbeperkt (alles)',
          },
          defaultValue: 'all',
          filterCallback: (row, filter) => {
            return filter === "all" || isWithinDeadline(row.deadline, filter)
          },
        },
        {
          title: 'Status',
          property: 'status',
          options: {
            'all': 'Alle',
            'pending': 'Open',
            'acknowledged': 'Afgerond',
            'expired': 'Verlopen',
          },
          defaultValue: 'all',
          filterCallback: (row, filter) => filter === "all" || row.status === filter,
        },
        {
          title: 'Onderwerp',
          property: 'subject',
          options: {
            'all': 'Alle',
            'Taak': 'Taak',
            'Afspraak': 'Afspraak',
            'Document': 'Document',
            'Terugbelverzoek': 'Terugbelverzoek',
            'Profielfoto': 'Profielfoto',
          },
          defaultValue: 'all',
          filterCallback: (row, filter) => filter === "all" || row.subject === filter,
        }
      ]}
      keyProperty={'id'}
      data={props.tasks}
      columns={[
        {header: "Deadline", property: 'deadline', transform: (deadline, task) => {
            const hasDeadlinePassed = task.status === "expired" || (task.status === 'pending' && new Date(task.deadline) < new Date)
            return <div className={"flex items-center " + (hasDeadlinePassed ? 'text-brand-error' : '')}>
              <FontAwesomeIcon icon={faClock} className={'mr-2'} />
              <div className={"flex flex-col"}>
                <span className={"whitespace-nowrap"}>{task.deadlineDate}</span>
                <span>{task.deadlineTime}</span>
              </div>
            </div>
          }},
        {header: "Taak", property: 'typeText', transform: (type, task) => {
            return <div>
              <div>
                <FontAwesomeIcon icon={task.typeIcon} className={"mr-1"} /> {type}
              </div>
              <div className={"text-xs"}>Onderwerp: {task.subject}</div>
            </div>
          }},
        {header: "Status", property: 'statusText', transform: (status, task) => {
            return <span className={classNames({
              'text-xs px-2 py-1 rounded bg-green-600 text-white': task.status === "acknowledged",
              'text-xs px-2 py-1 rounded bg-brand-primary text-brand-on-primary': task.status === "pending",
              'text-xs px-2 py-1 rounded bg-brand-error text-brand-on-error': task.status === "expired",
              'text-xs px-2 py-1 rounded bg-slate-400 text-black': ! ['acknowledged', 'pending', 'expired'].includes(task.status),
            })}>
                        { status }
                    </span>
          }},
        {header: "Toegewezen aan", property: 'performer'},
        {header: "Betreft", property: 'user'},
        {header: "Acties", property: 'id', transform: (id) => {
            return <Button size={"sm"} type={"secondary"} icon={faExternalLink} text={"Details"} onClick={() => {
              window.open(`/tasks/${id}`, '_blank')
            }} />
          }},
      ]}
    />
  </>
}

class ViewTask {
  id: string
  type: string
  subject: string
  typeText: string
  typeIcon: IconDefinition
  status: string
  statusText: string
  deadlineDate: string
  deadlineTime: string
  deadline: string
  performerId: number
  performerType: string
  performer: string
  user: string

  constructor(task: Task, performer: string, user: string) {
    this.id = String(task.id)
    this.type = task.type
    const description = task.description.toLowerCase()
    if (description.includes('document')) {
      this.subject = "Document"
    } else if (description.includes('afspraak') || task.description.includes('planning')) {
      this.subject = "Afspraak"
    } else if (description.includes('profielfoto')) {
      this.subject = 'Profielfoto'
    } else {
      this.subject = {
        'task': 'Taak',
        'planned_visit': 'Afspraak',
        'document': 'Document',
        'call': 'Terugbelverzoek',
      }[task.item_type]
    }
    this.typeText = {
      'call': 'Nabellen',
      'agenda_change': 'Afspraak gewijzigd',
      'agenda_remove': 'Afspraak geannuleerd',
      'sign_document': 'Document ondertekenen',
      'document_signed': 'Document ondertekend',
      'profile_picture': 'Profielfoto goedkeuring'
    }[task.type] ?? task.type
    this.typeIcon = {
      'call': faPhone,
      'agenda_change': faCalendarCheck,
      'agenda_remove': faCalendarTimes,
      'sign_document': faSign,
      'document_signed': faFileContract,
      'profile_picture': faCamera,
    }[task.type] ?? faCheckCircle
    this.status = task.status
    this.statusText = {
      'acknowledged': 'Afgerond',
      'expired': 'Verlopen',
      'pending': 'Open',
    }[task.status]
    this.deadline = task.deadline
    let deadline = new Date(task.deadline)
    this.deadlineDate = deadline.toISOString().split('T')[0]
    this.deadlineTime = deadline.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
    this.performerType = task.performer_type
    this.performerId = task.performer_id
    this.performer = performer
    this.user = user
  }

  search(search: string): boolean {
    return JSON.stringify(this).toLowerCase().includes(search.toLowerCase())
  }

  hasDeadlinePassed(): boolean {
    return (this.status === 'pending' && new Date(this.deadline) < new Date) || this.status === "expired"
  }

  isWithinDeadline(deadline: string): boolean {
    switch(deadline) {
      case '1d':
        return new Date(this.deadline) <= addDays((new Date()).getDay() === 5 ? 3 : 1, new Date)
      case '3d':
        return new Date(this.deadline) <= addDays(3, new Date)
      case '7d':
        return new Date(this.deadline) <= addDays(7, new Date)
      default:
        return new Date(this.deadline) <= addDays(1, new Date(deadline))
    }
  }
}
function isWithinDeadline(datetime: string, deadline: string): boolean {
  switch(deadline) {
    case '1d':
      return new Date(datetime) <= addDays((new Date()).getDay() === 5 ? 3 : 1, new Date)
    case '3d':
      return new Date(datetime) <= addDays(3, new Date)
    case '7d':
      return new Date(datetime) <= addDays(7, new Date)
    default:
      return new Date(datetime) <= addDays(1, new Date(deadline))
  }
}
function useTaskView(tasks: Task[]): ViewTask[] {

  const {teams} = useTeamData()
  const {clients} = useClientData()
  const {employees} = useEmployeesData()

  const getSubjectDetails = useCallback((type: string, id: number, sub: string|undefined|null) => {
    switch (type) {
      case "team": {
        let team = teams.find(t => t.id === id)
        return team ? `${team.name} (team)` : '-'
      }
      case "client": {
        let client = clients.find(c => c.id === id)
        return client ? `${client.name} (client: ${client.client_number})` : '-'
      }
      case "employee": {
        let employee = employees.find(e => e.sub === sub)
        return employee ? `${employee.name} (medewerker: ${employee.employee_number})` : '-'
      }
      default:
        return "Geen details beschikbaar"
    }
  }, [teams, clients, employees])

  return useMemo(() => tasks.map(task => new ViewTask(
    task,
    getSubjectDetails(task.performer_type, task.performer_id, null),
    getSubjectDetails(task.user_type, task.user_id, task.user_sub)
  )), [tasks, getSubjectDetails])
}

function addDays(n: number, t: Date): Date {
  return new Date(t.getTime() + n * (24 * 60 * 60 * 1000))
}
