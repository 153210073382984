import { useApiCall } from "@jane/lib/src/api";
import { Employee } from "@jane/lib/src/api/repositories/employees-repository"
import { useAuth } from "@jane/lib/src/auth/hook";
import { Card } from "@jane/lib/src/components/Card";
import { Badge } from "@jane/lib/src/components/content/Badge";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import React, {ChangeEvent, useRef, useState} from "react"
import {config} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faCircleNotch, faImage, faUpload} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@jane/lib/src/components/form/Button";

export default function PictureForm(props: {employee: Employee}): JSX.Element {

    const [isLoading, setIsLoading] = useState(false)
    const { employees } = useApiCall(config)
    const { token } = useAuth()
    const [hasUploaded, setHasUploaded] = useState(false)
    const [error, setError] = useState<string|null>(null)
    const uploadRef = useRef<HTMLInputElement>(null)
    const { showToast } = useToasts()
    function upload(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.item(0)
        if (!! file && file.type.startsWith('image/')) {
            setError(null)
            employees.updatePicture(props.employee.sub, file)
                .then(() => {
                    showToast('Foto geupload', 'Foto klaar met uploaden')
                    setHasUploaded(true)
                })
                // @ts-ignore
                .catch((error) => {
                    showToast('Upload mislukt', 'Er is iets misgegaan bij het uploaden van de foto')
                    setError('Er is iets misgegaan bij het uploaden van de foto: ' + error)
                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }
    return <Card title={'Profielfoto'}>
      <p className={'text-sm'}>
          De profielfoto van medewerkers wordt getoond aan clienten die een
          afspraak hebben met deze medewerkers.
      </p>
      {error!==null && <div className={"d-flex mb-3"}>
          <div className={"mr-2"}><Badge className={'bg-brand-error'}>X️ Error</Badge></div>
          <span className={"text-muted"}>
              {error}
          </span>
      </div>}
      {hasUploaded ? <div className={"flex items-center mt-4"}>
        <FontAwesomeIcon icon={faCheckCircle} className={"mr-2"} />️
        <p className={"text-sm leading-tight"}>
            De nieuwe profielfoto is opgeslagen, het kan enkele minuten duren voordat de wijzigingen zichtbaar zijn.
        </p>
      </div> : <div className="mt-4">
          { ! props.employee.employee_picture_url
              ? <div className="border border-brand-secondary text-brand-secondary flex justify-center items-center text-center rounded-full h-16 w-16" ><FontAwesomeIcon icon={faImage} /></div>
              : <img alt={""} src={`${props.employee.employee_picture_url}&accessToken=${token}`} className={"rounded-full h-16 w-16"} />
          }
        <div className="mt-4">
          <input ref={uploadRef} className={"sr-only"} type="file" accept="image/png,image/jpg,image/jpeg" disabled={isLoading} onChange={upload}/>
          {isLoading ? <div>
            <FontAwesomeIcon icon={faCircleNotch} spin={true} className={"mr-2"} /> Uploaden...
          </div> : <div>
           <Button type={'primary'} size={'sm'} text={'Nieuwe foto'} icon={faUpload} onClick={() => uploadRef.current?.click()} disabled={isLoading} />
          </div>}
        </div>
      </div>}
    </Card>
}
