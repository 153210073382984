import {Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {BuddyTeam, PhoneDirectoryEntry} from "@jane/lib/src/api/repositories/buddy-teams-repository";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import { SectionDivider } from "@jane/lib/src/components/content/SectionDivider";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import React, {FC, useMemo, useState} from "react";
import {config} from "../../config";
import {useTeamData} from "../../resources/TeamContext";
import {usePermissions} from "../../resources/PermissionsHook";
import {useTeamMap} from "../../components/team-select";
import { Select } from "@jane/lib/src/components/form/Select";
import {Button, IconButton} from "@jane/lib/src/components/form/Button";
import { InputErrors } from "@jane/lib/src/components/form/InputErrors";
import { ErrorBag } from "@jane/lib/src/api/call";
import { Card } from "@jane/lib/src/components/Card";
import { Toggle } from "@jane/lib/src/components/form/Toggle";
import {faEdit, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../../components/ModalProvider";
import {ConfirmationModal} from "../../modals/ConfirmationModal";
import {EditBuddyTeamModal} from "../../modals/EditBuddyTeamModal";


export default function TelephoneSettingsPage(): JSX.Element {
  const { buddyTeams: buddyTeamsApi, teams: teamsApi } = useApiCall(config)
  const { resource: buddyTeams, isLoading, reload } = useApiEndpoint(() => buddyTeamsApi.all())
  const { teams } = useTeamData()
  const { canListTeams, canListAllTeams } = usePermissions()
  const { resource: teamResource } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)
  const teamMap = useTeamMap(teams, teamResource ?? null, secondaryTeams ?? [])
  const teamOptionsMap: Map<number, [string, number]> = useMemo(() => {
    return new Map([
      ...teamMap
        .map<[Team, number]>(teams => {
          const team = teams.slice(-1)[0]
          return [team, teams.length]
        })
        .map<[number, [string, number]]>(([team, indentation]) => [team.id, [team.name, indentation]])
    ])
  }, [teamMap])
  const teamFilterOptions = useMemo(() => {
    return Array.from(teamOptionsMap).map(([id, [name, indentation]]: [number, [string, number]]): [string, string] => {
      return [id.toString(), (canListAllTeams ? "\xA0\xA0".repeat(indentation - 1) : "") + name]
    })
  }, [teamOptionsMap, canListAllTeams])

  return <ContentContainer>
    <PageHeader>Telefooncentrale</PageHeader>

    <p>
      Instellingen voor de telefooncentrale. Hier kunnen teams aan belgroepen gekoppeld worden.
    </p>

    <SectionDivider />

    <SectionHeader>Teams & Belgroepen</SectionHeader>

    {buddyTeams && <AddBuddyTeamForm buddyTeams={buddyTeams.buddyTeams} directory={buddyTeams.directory} teamOptions={teamFilterOptions} onSaved={() => reload()} />}

    {buddyTeams?.buddyTeams.map((buddyTeam,i) => {
      return <BuddyRow buddyTeam={buddyTeam} key={i} teams={teams} buddyTeams={buddyTeams} reload={() => reload()} />
    })}
  </ContentContainer>
}

const BuddyRow: FC<{buddyTeam: BuddyTeam, teams: Team[], buddyTeams: {buddyTeams: BuddyTeam[], directory: PhoneDirectoryEntry[]}, reload: () => void}> = ({buddyTeam, reload, buddyTeams, teams}) => {
  const { buddyTeams: buddyTeamsApi, teams: teamsApi } = useApiCall(config)
  const modal= useModal({title: 'Weet je het zeker?', body: <ConfirmationModal text={'Weet je zeker dat je deze belgroep koppeling wilt verwijderen?'} onConfirmed={ () => del(buddyTeam.id)} />})
  const editModal= useModal({title: 'Email aanpassen', body: <EditBuddyTeamModal team={buddyTeam} onFinished={ () => reload()} />})
  const del = async (buddyTeamId: string) => {
    await buddyTeamsApi.del(buddyTeamId)
    reload()
  }
  const teamLookupMap = teams.reduce((acc, team) => {
    acc[team.id] = team.name
    return acc
  }, {} as {[id: number]: string})
  const callGroupLookupMap = buddyTeams?.directory.reduce((acc, entry) => {
    acc[entry.extension] = entry
    if (entry.number) {
      acc[entry.number] = entry
    }
    return acc
  }, {} as {[extension: string]: PhoneDirectoryEntry}) ?? {}

  const callGroupTarget: PhoneDirectoryEntry|undefined = callGroupLookupMap[buddyTeam.target] ?? callGroupLookupMap[buddyTeam.target]

  return <div className={'mt-4'}>
    <Card>
      <div className={'flex items-stretch space-x-4'}>
        <div className={'flex-1'}>
          <div className={'text-sm font-medium'}>Team</div>
          <div>{teamLookupMap[buddyTeam.team_id]}</div>
        </div>
        <div className={'flex-1'}>
          <div className={'text-sm font-medium'}>Belgroep</div>
          <div>{`(${callGroupTarget?.extension}) ${callGroupTarget?.first_name} ${callGroupTarget?.last_name}`}</div>
          <div className={'text-sm'}>{buddyTeam.email ?? callGroupTarget?.user_id}</div>
        </div>
        <div className={'flex items-center space-x-4'}>
          <IconButton onClick={() => editModal.open()} type={'secondary'} size={'sm'} icon={faEdit} />
          <IconButton onClick={() => modal.open()} type={'danger'} size={'sm'} icon={faTimesCircle} />
          <Toggle value={buddyTeam.enabled} onChange={async (newValue) => {
            await buddyTeamsApi.addOrUpdate(buddyTeam.team_id, buddyTeam.target, newValue).then(reload)
            reload()
          }} />
        </div>
      </div>
    </Card>
  </div>
}

const AddBuddyTeamForm: FC<{
  buddyTeams: BuddyTeam[],
  directory: PhoneDirectoryEntry[],
  teamOptions: [string,string][], onSaved: () => void}> = props => {
  const [team, setTeam] = useState<string>()
  const [callGroup, setCallGroup] = useState<string>()
  const callGroupOptions = Object.fromEntries(props.directory.map(entry => [entry.extension, `(${entry.extension}) ${entry.first_name} ${entry.last_name}`]))
  const errors: ErrorBag = props.buddyTeams.map(t => t.team_id).includes(Number(team)) ? {'team': ['Dit team is al gekoppeld aan een belgroep']} : {}
  const {buddyTeams} = useApiCall(config)

  const save = async () => {
    if (Object.keys(errors).length > 0) return
    if (!callGroup || !team) return
    await buddyTeams.addOrUpdate(Number(team), callGroup, true)
    props.onSaved()
  }
  return <div>
    <div className={'flex items-end space-x-4'}>
      <div className={"flex-1"}>
        <Select label={"Team"} options={props.teamOptions} value={team ?? '0'} onChange={setTeam}/>
      </div>
      <div className={"flex-1"}>
        <Select label={"Belgroep"} options={callGroupOptions} value={callGroup ?? '0'} onChange={setCallGroup}/>
      </div>
      <Button text={'Toevoegen'} onClick={save} size={'sm'} disabled={Object.keys(errors).length > 0} type={'primary'}/>
    </div>
    <InputErrors field={'team'} errors={errors} />
  </div>
}