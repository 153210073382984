import {resolveConfig, ConfigInterface} from "@jane/lib/src/config";

const isProduction = process.env.NODE_ENV === 'production'

export const config: ConfigInterface = {
  isProduction,
  authRealmUrl: resolveConfig('VITE_AUTH_REALM_URL', 'https://login.miep.online/auth/'),
  authRealm: resolveConfig('VITE_AUTH_REALM', 'miep'),
  authClientId: resolveConfig('VITE_AUTH_CLIENT_ID', 'backoffice'),
  restApiUrl: resolveConfig('VITE_API_BASE_URL', 'https://api.miep.online/v1'),
  defaultRoute: '/',
  loginRoute: '/auth/login',
  publicRoutes: [],
  guestRoutes: [
    '/auth/login',
  ],
  fileViewerBaseUrl: resolveConfig('VITE_FILE_VIEWER_BASE_URL', 'http://localhost:3001/file',),
  rocketChatHostname: resolveConfig('VITE_CHAT_HOSTNAME', 'chat.miep.online'),
  rocketChatAuthUrl: resolveConfig('VITE_CHAT_AUTH_URL', 'https://chat.miep.online/api/v1/login'),
  telephoneServiceUrl: resolveConfig('VITE_TELEPHONE_SERVICE_URL', 'wss://api.miep.online/socket'),
  sentryDsn: resolveConfig('VITE_SENTRY_DSN', ''),
}