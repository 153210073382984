import React, {ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState} from "react";
import {ChatMessage, ChatUser, useChatContext} from "./provider";
import {useAuth} from "../auth/hook";
import {Loading} from "../components/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisVertical,
  faInfoCircle,
  faPaperclip,
  faTimes,
  faTrash,
  faWarning
} from "@fortawesome/free-solid-svg-icons";

interface Message {
    message: ChatMessage
    user: ChatUser|null|undefined
}
export const ActiveChat: React.FC = () => {

    const { user } = useAuth()
    const {messages, chat, currentConversationId, currentChatMembers, users, sendAttachment, sendMessage: sendChatMessage, connected, loaded, loadMoreHistory, hasUnloadedHistory} = useChatContext()
    const [isContextMenuExpanded, setIsContextMenuExpanded] = useState(false)

    const top = useRef<HTMLDivElement>(null)
    const bottom = useRef<HTMLDivElement>(null)

    const messageHistory: Message[] = useMemo(() => {
        return messages.filter(m => m.conversationId === currentConversationId).map(m => {
            return {
                message: m,
                user: users.find(u => u.id === m.userId)
            }
        }).sort((a,b) => {
            return a.message.timestamp.getTime() < b.message.timestamp.getTime() ? -1 : 1
        })
    }, [messages, currentConversationId, users])

    const [isLoadingHistory, setIsLoadingHistory] = useState(false)
    const messagesSortedByTime = useMemo(() => {
        return messages.sort((a, b) => a.timestamp.getTime() < b.timestamp.getTime() ? 1 : -1)
    }, [messages])
    const lastMessage: ChatMessage|undefined = messagesSortedByTime.find(m => m.conversationId === currentConversationId)
    useEffect(() => {
        bottom.current?.scrollIntoView()
    }, [lastMessage, bottom.current])


    const [draft, setDraft] = React.useState<string>("")
    const fileReaderRef = useRef<HTMLInputElement>(null)

    const sendMessage = (event: FormEvent) => {
        event.preventDefault()
        if (currentConversationId && draft) {
            sendChatMessage(currentConversationId, draft)
            setDraft("")
        }
    }

    const loadMore = async () => {
        if (chat) {
            setIsLoadingHistory(true)
            await loadMoreHistory(chat.id, 300)
            setIsLoadingHistory(false)
            top.current?.scrollIntoView()
        }
    }

    const shouldShowBuddyBanner = useMemo(() => {
        const currentMember = currentChatMembers.find(m => m.sub === user?.sub)
        if (chat?.type === "network" || chat?.type === "team") {
            return false
        }
        return ['manager', 'employee', 'coordinator'].includes(String(currentMember?.role))
            && currentChatMembers.filter(m => !m.explicit && m.role === "coordinator").length > 0
    }, [currentChatMembers, user, chat])

    if (! connected || ! loaded) {
        return <div className={"flex-1 flex flex-col items-center justify-center"}>
          <Loading loading={true}>&nbsp;</Loading>
          <div className={"text-lg font-bold"}>Verbinding maken met MiepChat&#8230;</div>
        </div>
    }

    if (currentConversationId === null) {
        return <div className={"bg-gray-50 flex-1"}>
        </div>
    }

    function roleToString(role: string): string {
        switch (role.toLowerCase()) {
            case 'manager': return "Manager";
            case 'coordinator': return "Coördinator";
            case 'employee': return "Medewerker";
            case 'client': return "Cliënt";
            case 'client_contact': return "Netwerklid";
            default: return role;
        }
    }

    function typeToString(type: string): string {
        switch (type.toLowerCase()) {
            case 'network': return "Netwerk chat";
            case 'team': return "Team chat";
            case 'message': return "Prive chat";
            case 'group': return "Groeps chat";
            default: return type;
        }
    }

    function promptForFile(): Promise<File|null> {
        return new Promise((resolve) => {
          // @ts-ignore
            fileReaderRef.current.onchange = (e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                  resolve(e.target.files[0])
              } else {
                  resolve(null)
              }
          }
          fileReaderRef.current?.click()
        })
    }

    function chooseAttachment() {
        promptForFile().then(file => {
            if (file && currentConversationId) {
                sendAttachment(currentConversationId, file)
            }
        })
    }

    return <div className={"flex-1 flex flex-col"}>

        {/* Chat header */ }
        <div className={"h-16 flex items-center justify-between px-4 border-b border-gray-100"}>
            <div className={"text-lg font-bold"}>{chat?.name}</div>
            <button className={"hover:bg-gray-50 h-7 w-7 flex items-center justify-center rounded-full relative"} onClick={() => setIsContextMenuExpanded(current => !current)}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
                { isContextMenuExpanded && chat && <div className={"absolute w-500 top-0 right-0 flex flex-col whitespace-nowrap rounded-sm bg-white shadow-lg"} style={{minWidth: '300px'}}>
                    <div className={"px-4 pt-4 pb-2 font-medium flex items-center justify-between"}>
                        <div className={"flex items-center"}>
                            { typeToString(chat.type)}
                        </div>
                        <div className={"bg-gray-50 rounded-full text-center ml-2 h-6 w-6"}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className={"px-4 pb-4 text-gray-500 text-sm flex items-center justify-between border-b border-gray-100"}>
                        <div className={"flex items-center"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            In deze chat:
                        </div>
                    </div>
                    <div className={"p-2"}>
                        {currentChatMembers.filter(u => u.sub !== 'fourfusion').map((user, i) => <div key={i} className={"px-2 py-2 flex items-center"}>
                            {!! user.employee_picture_url ? <>
                                <img className={"h-10 w-10 rounded-full"} src={user.employee_picture_url} />
                            </> : <>
                                <div className={"h-10 w-10 rounded-full bg-red-400 text-white flex items-center justify-center"}>{user.name[0]}</div>
                            </>}
                            <div className={"font-medium px-3 flex flex-col items-start"}>
                                <div>{user.name}</div>
                                <div className={"font-light text-xs text-gray-500"}>{roleToString(user.role)}{user.explicit ? '' : ' (Automatisch toegevoegd)'}</div>
                            </div>
                        </div>)}
                    </div>
                </div>}
            </button>
        </div>

        {/* Chat messages */}
        { shouldShowBuddyBanner ? <>
            <div className={"px-4 py-3 bg-blue-200 text-blue-900 font-medium flex"}>
                <FontAwesomeIcon icon={faWarning} />
                <div className={"ml-3"}>
                    Een buddy coördinator leest mee met dit gesprek: ({currentChatMembers.filter(m => !m.explicit && m.role === "coordinator").map(m => m.name).join(', ')}).
                </div>
            </div>
        </> : <></> }
        <div className={"flex-1 bg-gray-50 p-4 overflow-y-scroll"}>
            <div ref={top}></div>
            {chat && <>
                <div className={"text-center"}>
                    {hasUnloadedHistory(chat.id) ? <>
                        <button className={"bg-blue-500 text-sm text-white rounded-full py-1 px-3"} disabled={isLoadingHistory} onClick={loadMore}>{isLoadingHistory ? "Laden..." : "Laad oudere berichten"}</button>
                    </> : <>
                        <p className={"text-gray-500 text-sm"}>Dit is de start van het gesprek</p>
                    </>}
                </div>
            </>}
            { messageHistory.map((m, i) => {
                if (user?.sub === m.user?.id) {
                    return <OutgoingChatBubble key={i} message={m.message} timestamp={m.message.timestamp} id={m.message.id} />
                }
                return <IncomingChatBubble
                    key={i}
                    name={m.user?.name ?? "Onbekende gebruiker"}
                    timestamp={m.message.timestamp}
                    imageUrl={m.user?.avatar ?? "https://blog.cogitactive.com/wp-content/uploads/sites/2/2019/12/Mystery-Man-300x300.png"}
                    message={m.message}
                />
            }) }
            <div ref={bottom}></div>
        </div>

        {/* Chat text input */}
        <div className={""}>
            <form className={"flex items-center justify-between px-3 py-3 "} onSubmit={sendMessage}>
                <button
                    className={"hover:text-black hover:bg-gray-100 text-gray-600 text-lg disabled:text-gray-500 h-10 w-10 flex rounded-full items-center justify-center"}
                    type={"button"}
                    disabled={!currentConversationId}
                    onClick={() => chooseAttachment()}
                >
                    <FontAwesomeIcon icon={faPaperclip} />
                </button>
                <input type="file" ref={fileReaderRef} className={"hidden"}/>
                <input
                    className={"w-full border-gray-200 px-2 py-2 text-md rounded-lg mr-2"}
                    type={"text"}
                    placeholder={"Type een bericht"}
                    value={draft}
                    onChange={e => setDraft(e.target.value)}
                />
                <button
                    className={"hover:text-black text-blue-500 disabled:text-gray-500 h-7 w-7 flex items-center justify-center"}
                    type={"submit"}
                    disabled={!currentConversationId || !draft}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-send-fill rotate-45"
                        viewBox="0 0 16 16"
                    >
                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                    </svg>
                </button>
            </form>
        </div>

    </div>
}

const IncomingChatBubble: React.FC<{name: string, timestamp: Date, imageUrl: string, message: ChatMessage}> = props => {
    const timeText = useMemo(() => {
        const time = props.timestamp.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
        const t = props.timestamp
        const daysAgo = Math.round((new Date().getTime() - t.getTime()) / (1000 * 60 * 60 * 24))
        if (daysAgo === 0) {
            return time
        } else if (daysAgo < 7) {
            return t.toLocaleDateString('nl-NL', { weekday: 'short'}) + " · " + time
        } else {
            return t.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric'}) + " · " + time
        }
    }, [props.timestamp])
    return <div className={"flex justify-start"}>
        <div className={"flex items-start max-w-lg mt-4"}>
            <div className={"h-12 w-12 rounded-full mr-4 overflow-hidden flex items-center justify-center"}>
              <img src={props.imageUrl} alt={""} className={""} />
            </div>
            <div className={'flex-1'}>
                <div className={"flex items-center justify-between"}>
                    <div className={"font-bold text-gray-900"}>{ props.name }</div>
                    <div className={"text-gray-500 text-sm ml-3"}>{ timeText }</div>
                </div>
                <div className={"bg-gray-200 text-black rounded-lg my-2"}>
                    <MessageBubbleContent message={props.message} />
                </div>
            </div>
        </div>
    </div>
}

const OutgoingChatBubble: React.FC<{timestamp: Date, message: ChatMessage, id: string}> = props => {
    const timeText = useMemo(() => {
        const time = props.timestamp.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
        const t = props.timestamp
        const daysAgo = Math.round((new Date().getTime() - t.getTime()) / (1000 * 60 * 60 * 24))
        if (daysAgo === 0) {
            return time
        } else if (daysAgo < 7) {
            return t.toLocaleDateString('nl-NL', { weekday: 'short'}) + " · " + time
        } else {
            return t.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric'}) + " · " + time
        }
    }, [props.timestamp])
    const {deleteMessage} = useChatContext()
    const onDelete = () => {
        if (window?.confirm("Wil je het bericht verwijderen? Dit kun je niet ongedaan maken!")) {
            deleteMessage(props.id)
        }
    }
    return <div className={"flex justify-end"}>
        <div className={"flex items-start max-w-lg mt-4"}>
            <div>
                <div className={"flex items-center justify-between"}>
                    <div className={"font-bold text-gray-900"}>Ik</div>
                    <div className={"text-gray-500 text-sm ml-3"}>{ timeText }</div>
                </div>
                <div className={"flex justify-end"}>
                    <div className={"bg-blue-500 text-white rounded-lg my-2"}>
                        <MessageBubbleContent message={props.message} />

                    </div>
                    <button className={"h-6 w-6 m-2 z-10 rounded-full bg-gray-50 hover:bg-red-500 text-red-400 border flex items-center justify-center hover:text-white text-xs"} onClick={onDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
        </div>

    </div>
}
const MessageBubbleContent: React.FC<{message: ChatMessage}> = props => {
    const {formatMediaUrl} = useChatContext()
    const [imageFullScreen, setImageFullScreen] = useState(false)
    if (props.message.file === undefined) {
        return <p className={"m-0 px-3 py-2"}>{ props.message.message }</p>
    }
    const attachment = props.message.attachments?.[0] as {
        title_link: string
        image_dimensions: {width: number, height: number},
        image_url: string
        image_type: string
        image_size: number
    }|undefined
    if (props.message.file.type.startsWith("image/") && attachment !== undefined && attachment.image_url !== undefined) {
        return <>
            {imageFullScreen && <div className={"fixed top-0 left-0 z-[999] w-full h-full bg-black bg-opacity-90 flex items-center justify-center"} onClick={() => setImageFullScreen(false)}>
                <img src={formatMediaUrl(attachment.title_link as string)} className={"rounded-lg max-h-screen max-w-screen cursor-zoom-out"} alt={''} />
            </div>}
          <img src={formatMediaUrl(attachment.image_url as string)} className={"rounded-lg cursor-zoom-in"} alt={''} onClick={() => setImageFullScreen(true)} />
        </>
    }
    const fileAttachment = props.message.attachments?.[0] as {
        title: string
        title_link: string
        type: string
    }|undefined
    if (fileAttachment !== undefined && fileAttachment.title !== undefined) {
        return <button className={'p-3 flex items-center hover:bg-[#fff2] m-1 cursor-pointer rounded'} onClick={() => window?.open(formatMediaUrl(fileAttachment.title_link as string))}>
            <FontAwesomeIcon icon={faDownload} />
            Download {fileAttachment.title}
        </button>
    }
    return <div>{props.message.message}</div>
}