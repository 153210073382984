import {AbstractRepository} from "../repository";
import {data} from "autoprefixer";

export type KeycloakUser = {
    sub: string
    email: string
    enabled: boolean
    name: string
    given_name: string
    family_name: string
    preferred_username: string
}
export type Employee = KeycloakUser & {
    id: number
    role_name: string
    employee_picture_url: string|null
    employee_number: string|null
    team_id: number|null
    actions?: string[]|null
}

export default class EmployeesRepository extends AbstractRepository {
    /**
     * List all employees
     */
    public all(): Promise<Employee[]> {
        return this.request<{data: Employee[]}>('GET', 'employees')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Returns a single Employee by ID
     */
    public getById(userSub: string): Promise<Employee&{roles:string[]}> {
        return this.request<{data: Employee&{roles:string[]}}>('GET', `employees/${userSub}`)
          .then(data => data.data)
    }

    /**
     * Update role
     */
    public updateRole(subject: string, role: "employee"|"coordinator", additionalRoles?: string[]): Promise<void> {
        return this.request('POST', `set_user_permission/${subject}`, { role, additionalRoles })
            .then(() => {})
    }

    /**
     * Update employee picture
     */
    public updatePicture(subject: string, picture: File): Promise<void> {
        return this.request('POST', `employee/${subject}/picture`, picture)
            .then(() => {})
    }

  /**
   * Fetch employee picture
   */
  public fetchPicture(subject: string): Promise<string|null> {
    return this.request<{ data: {employee_picture_url: string|null} }>('GET', `employee/${subject}/profile_picture`)
      .then((r) => r.data?.employee_picture_url)
  }

  /**
   * Approve employee picture
   */
  public approvePicture(subject: string): Promise<void> {
    return this.request('POST', `employee/${subject}/picture_approve`, {})
      .then(() => {})
  }

  /**
   * Deny employee picture
   */
  public denyPicture(subject: string): Promise<void> {
    return this.request('POST', `employee/${subject}/picture_deny`, {})
      .then(() => {})
  }

    /**
     * Invite employee account
     */
    public invite(subject: string): Promise<void> {
        return this.request('POST', `employee/${subject}/invite`, 'POST')
            .then(() => {})
    }

    /**
     * Reset 2FA for an employee
     */
    public reset2fa(subject: string): Promise<void> {
        return this.request('POST', `reset_2fa/${subject}`, 'POST')
            .then(() => {})
    }
}
