import {Employee, useApiCall} from "@jane/lib/src/api";
import React, {useMemo, useEffect, useState} from "react"
import {JaneEmployee, useEmployeesData} from "../resources/EmployeeContext";
import {config} from "../config";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import { Select } from "@jane/lib/src/components/form/Select";
import { Button } from "@jane/lib/src/components/form/Button";
import { Card } from "@jane/lib/src/components/Card";

export default function RolesForm(props: {employee: JaneEmployee, enrichedEmployee: Employee&{roles:string[]}, reload: () => void}): JSX.Element {
  const currentRole = props.employee.role === "Werknemer" ? "employee" : "coordinator"
  const rolesMap = {
    "employee": "Werknemer",
    "coordinator": "Coordinator",
  }
  const [role, setRole] = useState<"employee"|"coordinator">(currentRole)
  const [additionalRoles, setAdditionalRoles] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { employees } = useApiCall(config)
  const { showToast } = useToasts()


  // @ts-ignore
  const roles = useMemo(() => {
    return Array.isArray(props.enrichedEmployee.roles) ? props.enrichedEmployee.roles : [];
  }, [props.enrichedEmployee]);
  const filteredRoles = ['telephone', 'hoofdcoordinator', 'learn', 'operationalmanager'];
  useEffect(() => {
    setAdditionalRoles(roles.filter((role: string) => filteredRoles.includes(role)));
  }, [roles]);

  function save() {
    setError('')
    setIsLoading(true)
    employees.updateRole(props.employee.sub, role, additionalRoles)
      .then(() => {
        showToast('Wijzigingen opgeslagen', 'Het kan een minuut duren voordat de wijziging is doorgevoerd, herlaad straks de pagina om de wijziging te zien.')
        setRole(role)
        props.reload()
      })
      .catch(err => {
        setError(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCheckboxChange = (roleName: string, isChecked: boolean) => {
    setAdditionalRoles((prevRoles) => {
      if (isChecked) {
        return [...prevRoles, roleName];
      } else {
        return prevRoles.filter((role) => role !== roleName);
      }
    });
  };

  return <Card title={'Rol aanpassen'} id={"role"}>
    <div className={"space-y-4"}>
      <Select options={rolesMap} value={role} onChange={(e) => setRole(e as "employee" | "coordinator")}
              label={'Role'}/>

      {role === 'coordinator' && <>
        <h1 className={"pt-3 font-bold"}>Extra rollen</h1>
        <div className={'grid grid-cols-1 lg:grid-cols-2 gap-4'}>
          <label className={`flex space-x-2 hover:cursor-pointer border border-brand-on-primary-container border-opacity-25 ${additionalRoles.includes('hoofdcoordinator') ? 'bg-brand-on-primary-container bg-opacity-5' : ''} rounded-lg p-2`}>
            <input className={'mx-2'} type="checkbox" onChange={(e) => handleCheckboxChange('hoofdcoordinator', e.target.checked)} checked={additionalRoles.includes('hoofdcoordinator')}/>
            <div className={''}>
              <h1 className={'font-medium mb-1'}>Hoofdcoordinator</h1>
              <p className={'text-sm'}>Gelijke rechten aan een reguliere coördinator, maar dan met toegang tot alle teams.</p>
            </div>
          </label>
          <label className={`flex space-x-2 hover:cursor-pointer border border-brand-on-primary-container border-opacity-25 ${additionalRoles.includes('operationalmanager') ? 'bg-brand-on-primary-container bg-opacity-5' : ''} rounded-lg p-2`}>
            <input className={'mx-2'} type="checkbox" onChange={(e) => handleCheckboxChange('operationalmanager', e.target.checked)} checked={additionalRoles.includes('operationalmanager')}/>
            <div className={''}>
              <h1 className={'font-medium mb-1'}>Operational Manager</h1>
              <p className={'text-sm'}>Gelijke rechten aan een reguliere coördinator, maar kan taken zien van alle teams.</p>
            </div>
          </label>
          <label className={`flex space-x-2 hover:cursor-pointer border border-brand-on-primary-container border-opacity-25 ${additionalRoles.includes('learn') ? 'bg-brand-on-primary-container bg-opacity-5' : ''} rounded-lg p-2`}>
            <input className={'mx-2'} type="checkbox" onChange={(e) => handleCheckboxChange('learn', e.target.checked)} checked={additionalRoles.includes('learn')}/>
            <div className={''}>
              <h1 className={'font-medium mb-1'}>Learn</h1>
              <p className={'text-sm'}>Kan de inhoud en voortgang beheren van de <strong>Samen Wijzer</strong> kennisbank.</p>
            </div>
          </label>
          <label className={`flex space-x-2 hover:cursor-pointer border border-brand-on-primary-container border-opacity-25 ${additionalRoles.includes('telephone') ? 'bg-brand-on-primary-container bg-opacity-5' : ''} rounded-lg p-2`}>
            <input className={'mx-2'} type="checkbox" onChange={(e) => handleCheckboxChange('telephone', e.target.checked)} checked={additionalRoles.includes('telephone')}/>
            <div className={''}>
              <h1 className={'font-medium mb-1'}>Telefonie</h1>
              <p className={'text-sm'}>Heeft toegang tot de telefoontab van de web omgeving.</p>
            </div>
          </label>
        </div>
      </>}

      <Button type={'primary'} size={'sm'} disabled={isLoading} text={'Opslaan'} onClick={save}/>
      {error === "" ? "" : <>
        <div className={"text-danger mt-2"}>{error}</div>
      </>}
    </div>
  </Card>
}
